.alternatePhoneNumber {
    display: none;
}
.locationPreference2 {
    display: none;
}

.locationPreference3 {
    display: none;
}

.parentFormClass .formio-component-button {
    font-family: 'Noto Sans Medium';
    background-color: #00000000 !important;
    text-transform: uppercase;
    font-size: 0.8rem;
    padding: 0.5rem 2rem;
    margin: 2rem 0 5rem 0;
    text-align: left;

}
.parentFormClass .showAlternatePhoneNumber {
    font-family: 'Noto Sans Medium';
    background-color: #00000000;
    text-transform: uppercase;
    color: #ff6b00 !important;
    font-size: 0.8rem;
    font-weight: 600;
    border: none;

}

.parentFormClass .showLocationPreference2 {
    font-family: 'Noto Sans Medium';
    background-color: #00000000;
    text-transform: uppercase;
    color: #ff6b00 !important;
    font-size: 0.8rem;
    font-weight: 600;
    border: none;

}

.parentFormClass .showlocationPreference3 {
    font-family: 'Noto Sans Medium';
    background-color: #00000000;
    text-transform: uppercase;
    color: #ff6b00 !important;
    font-size: 0.8rem;
    font-weight: 600;
    border: none;

}