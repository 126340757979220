.TrainingSession {
    padding: 10px;
    overflow-x: hidden;
}

.TrainingSession h1 {
    color: rgb(63, 123, 235)
}

.TrainingSession p {
    display: inline-block;
    background-color: lightgray;
    padding: 5px 15px;
    border-radius: 2px;
}

.TrainingSession .formio-component-button {
    font-family: 'Noto Sans Medium';
    background-color: inherit;
    text-transform: uppercase;
    font-size: 0.8rem;
    padding: 0px;
    margin: 0px;
    text-align: center;
  }

.TrainingSession .btn-primary,.TrainingSession .btn-primary.disabled, .TrainingSession .btn-primary:disabled {
    background-color: #FF712D !important;
    border-color: #FF712D !important;
}

.TrainingSession .form-control.is-invalid, .was-validated .form-control:invalid {
    background-image: none !important;
    border: none;
}