@font-face {
    font-family: 'Noto Sans Light';
    src: url('./../fonts/NotoSans-ExtraLight.ttf');
  }
.data-table{
    text-align: center;
  border-collapse: collapse;
  /* border: 2px solid #FF712D; */
  border-radius: 5px;
  width: calc(70% + 50px);
}
.data-table-content {
  width: 100%;
  border-collapse: collapse;
}
.padding-class {
  padding-left: 20%;
}
.data-td, .data-th {
border: 2px solid #FF712D;
  padding: 8px;
}

.data-body .data-tr:nth-child(even){
  background-color: #f2f2f2;
}
.data-th {
  padding: 8px;
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: center;
  /* background-color: #4CAF50; */
}

@media screen and (max-width: 500px) {
  .padding-class {
    padding-left: 5%;
  }
}