@font-face {
    font-family: 'Noto Sans Light';
    src: url('./../fonts/NotoSans-ExtraLight.ttf');
  }
.illustration {
	/* background: url('/image/illustration.png');
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center bottom;
    height: 0; */
    /* padding-top: 90%; */
}
body {
    font-family: "Noto Sans Light";
    overflow-y: auto;
    background-color: #f1f1f1;
}
.mitraName h3, .mitraName h2 {
    font-size: 1rem;
    text-align: left;
    padding: 1rem;
    background-color: #ffffff;
}
.mitraName h2 {
    cursor: pointer;
    font-size: 0.9rem !important;
    background-color: #b6b6b6 !important;
}
.main-class {
    margin: 3rem 0;
}
.mitraName span {
    float: right;
}
.Home.submit-mitra {
    margin:0 auto;
}