.vahan_logo {
    max-width: 100px;
}

.training_session_main {
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 120px;
    flex-wrap: wrap;
    text-align: center;
}

.signout_button{
    background-color: lightgray;
    padding: 5px 20px;
    border-radius: 2px;
    box-shadow: 1px 1px 4px black;
    border: 1px solid black;
    /* color: #e7e5e5; */
    transition: 0.25s
}

.signout_button:hover {
    background-color: #FF712D;
    color: #e7e5e5;
}

.training_session_main .back_icon {
    color: #0F349A;
    font-size: 30px;
}