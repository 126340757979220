.contact-wrapper {
	width: 100%;
    height: 52px;
    vertical-align: middle;
    padding-top: 8px;
    /* padding-left: 46px; */
    /* display: flex; */
    /* background-color: white; */
    /* flex-direction: row; */
    /* justify-content: center; */
	/* border-bottom: 1px solid rgb(243, 243, 243); */
}
.single-ontact-container{
	text-decoration: none;
	 display: flex;
	 height: 40px
}

.dismiss-button{
	padding-top: 50px;
}
.candidate-contact-wrapper {
	width: 100%;
	height: 40px;
	padding-top: 10px;
	display: flex;
	background-color: #f5f5f5;
	flex-direction: row;
	justify-content: center;
	border-bottom: 1px solid rgb(243, 243, 243);
}
.candidate {
	color: rgba(2, 2, 2, 0.87);
	font-weight: bold;
	margin-right: 5px;
}
.candiate-detail {
	text-align: center;
	font-size: 16px;
	font-weight: bold;
	flex: 3;
	margin-left: 60px;
}
.category-icon-body {
	flex: 1 1;
}
.close-icon-body {
	flex: 0.75 1;
}
.category-body {
	flex: 4;
}
.candidate-icon-body {
	flex: 1;
}
.contact-medium {
	font-weight: bold;
	padding-left: 20px;
}
body {
	background-color: #d3d0cb;
	text-align: center;
}
body .footer {
	position: fixed;
	left: 0px;
	bottom: 0px;
	right: 0px;
	width: 100%;
	height: 40px;
	line-height: 40px;
	text-align: left;
	font-size: 14px;
	background-color: #9fb1bc;
	color: #fff;
	padding-left: 20px;
}
body button.example {
	width: 50%;
	margin: 7rem 0;
	background-color: #e2c044;
	color: #fff;
	padding: 10px;
	font-size: 18px;
}
button {
	border: none;
	background-color: transparent;
	-webkit-tap-highlight-color: rgba(0, 0, 0, 0);
	-webkit-tap-highlight-color: transparent;
}
button:focus {
	outline: none;
}
.bottom-sheet-wrapper {
	-webkit-transition: opacity 500ms cubic-bezier(0.03, 0.72, 0.36, 0.99);
	-moz-transition: opacity 500ms cubic-bezier(0.03, 0.72, 0.36, 0.99);
	-ms-transition: opacity 500ms cubic-bezier(0.03, 0.72, 0.36, 0.99);
	-o-transition: opacity 500ms cubic-bezier(0.03, 0.72, 0.36, 0.99);
	transition: opacity 500ms cubic-bezier(0.03, 0.72, 0.36, 0.99);
	position: fixed;
	top: 0px;
	left: 0px;
	z-index: 998;
	width: 100%;
	height: 100%;
	opacity: 0;
}
.bottom-sheet-wrapper .bottom-sheet-block-layer {
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.8);
}
.bottom-sheet-wrapper .bottom-sheet {
	-webkit-transition: transform 500ms cubic-bezier(0.03, 0.72, 0.36, 0.99);
	-moz-transition: transform 500ms cubic-bezier(0.03, 0.72, 0.36, 0.99);
	-ms-transition: transform 500ms cubic-bezier(0.03, 0.72, 0.36, 0.99);
	-o-transition: transform 500ms cubic-bezier(0.03, 0.72, 0.36, 0.99);
	transition: transform 500ms cubic-bezier(0.03, 0.72, 0.36, 0.99);
	-webkit-transform: translate3d(0px, 100%, 0);
	-moz-transform: translate3d(0px, 100%, 0);
	-ms-transform: translate3d(0px, 100%, 0);
	-o-transform: translate3d(0px, 100%, 0);
	transform: translate3d(0px, 100%, 0);
	position: absolute;
	bottom: 0px;
	z-index: 999;
	width: 100%;
	background-color: #fff;
}
.bottom-sheet-wrapper.hide {
	display: none;
}
.bottom-sheet-wrapper .bottom-sheet-item {
	display: block;
	width: 100%;
	background-color: #fff;
	text-align: center;
	padding: 20px 0;
	border: none;
	border-top: 1px solid #ededed;
	font-size: 16px;
}
.bottom-sheet-wrapper .bottom-sheet-item.cancel {
	color: #d0021b;
}
.bottom-sheet-wrapper .toast {
	bottom: 110%;
}
.bottom-sheet-wrapper.enter {
	opacity: 1;
}
.bottom-sheet-wrapper.enter .bottom-sheet {
	-webkit-transform: translate3d(0px, 0px, 0);
	-moz-transform: translate3d(0px, 0px, 0);
	-ms-transform: translate3d(0px, 0px, 0);
	-o-transform: translate3d(0px, 0px, 0);
	transform: translate3d(0px, 0px, 0);
}


.popup-container{
	width: 300px;
	height:300px
}