.TermsAndConditions {
    overflow-x: hidden;
}

.TermsAndConditions strong {
    margin-right: 30px;
}

.TermsAndConditions .display_flex {
    display: flex;
    text-align: justify;
}

.display_flex span {
    margin-left: 15px
}

.TermsAndConditions h4 {
    padding: 60px 0px 10px;
}

.TermsAndConditions h5 {
    display: flex;
    padding: 50px 0px;
}

.TermsAndConditions h5 span {
    text-decoration: underline;
}

.TermsAndConditions .display_flex strong{
    font-weight: normal;
}

@media screen and (max-width: 768px) {
    .TermsAndConditions strong {
        margin-right: 10px;
    }

    .TermsAndConditions h5 {
        display: flex;
        padding: 30px 0px;
    }
}