.AdditionalInfo1 {
    padding: 10px;
    min-height: 100vh;
}

.AdditionalInfo1 img {
    width: 150px !important;
    max-height: 100px;
    cursor: pointer;
}

.AdditionalInfo1 .page-link {
    color: gray !important;
}

.AdditionalInfo1 .page-link:hover {
    text-decoration: none !important;
    transform: none !important;
    font-weight: normal !important;
    background-color: inherit !important;
}

.AdditionalInfo1 .page-item.active .page-link {
    background-color: #FF712D !important;
    border: none !important;
    color: white !important;
}

.AdditionalInfo1 .btn-primary {
    background-color: #FF712D !important;
    border: none !important;
}

.AdditionalInfo1 .btn-secondary {
    background-color: gray !important;
}