.illustration {
	background: url('/image/illustration.png');
	background-repeat: no-repeat;
	background-position: center bottom;
	padding-top: 300px;
	/* position: fixed; */
	/* z-index: 999; */
}
.vahan-logo {
    max-width: 150px;
}
.vahan{
	margin-bottom: 2.5rem;
}
.login-font {
	font-family: "Gilroy ExtraBold";
}

@media screen and (max-width: 500px) {
	.illustration {
		padding-top: 180px;
	}
	.vahan-logo {
		max-width: 100px;
		margin-top: 0 !important;
		float: left;
		padding-left: 5%;
	}
	.vahan {
		float: right;
		padding-right: 5%;
	}
	.cards-elements-surface-filled {
		border: 0 !important;
		background-color: #f1f1f1;
		margin-top: 30%;
		box-shadow: none;
	}
	h3.subheading {
		padding-right: 28%;
		line-height: 100px;
	}
	.arrow-back {
		position: absolute;
		left: 45%;
	}
}

.signup_link {
	color: #FF712D;
	margin-left: 5px
}