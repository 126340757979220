.navigation-wrapper {
	width: 100%;
	height: 75px;
	display: flex;
	justify-content: center;
	background-color: #ffffff;
	box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
}
.navigation-menu {
	height: 100%;
	width: 90px;
}
.navigation-text {
	color: #3d3c3a;
	font-weight: bold;
	font-size: 12px;
	letter-spacing: 0;
	line-height: 17px;
	text-align: center;
}
.active-navigation-tab {
	font-size: 14px;
	font-family: 'Gilroy ExtraBold';
}
.nav-icon {
	padding-top: 25px;
}
