.signup_form .formio-component-button {
    font-family: 'Noto Sans Medium';
    background-color: inherit;
    text-transform: uppercase;
    font-size: 0.8rem;
    padding: 0px;
    margin: 0px;
    text-align: center;
  }

/* .Signup .vahan {
    margin-bottom: 50px;
} */