.dropdown {
    position: relative;
    /* display: inline-block; */
}

.dropdown-content {
    display: none;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
    padding: 12px 16px;
    z-index: 1;
    right: 0;
}

.dropdown:hover .dropdown-content {
    display: block;
}

.hoverClass:hover {
    background: #dedede;
    cursor: pointer;
}
.bottomMargin {
    overflow-y: auto;
    max-height: 100vh;
    margin-bottom: 50%;
}
.modal {
    display: none; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    padding-top: 100px; /* Location of the box */
    left: 0;
    bottom: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: auto; /* Enable scroll if needed */
    background-color: rgb(0, 0, 0); /* Fallback color */
    background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
}

/* Modal Content */
.modal-content {
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 80%;
}

/* The Close Button */
.close {
    color: #aaaaaa;
    float: right;
    font-size: 28px;
    font-weight: bold;
}

.close:hover,
.close:focus {
    color: #000;
    text-decoration: none;
    cursor: pointer;
}
/* Small devices (portrait tablets and large phones, 600px and up) */
@media screen and (min-width: 600px) {
    .recommendationSticker {
        float: left;
    }
    .modal-contents {
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 100%;
    }
}

/* ---------------------------- */

.imageLogo {
    max-width: 60%;
}
.jumbotron {
    background: rgb(220, 218, 218);
    width: 100%;
    line-height: 2;
    margin-top: 10px;
    border-radius: 10px;
}
.badges {
    display: inline-block;
    padding: 9px 26px;
    font-size: 0.75em;
    font-weight: 700;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    margin-left: 3px;
    border-radius: 0.25rem;
    background: grey;
}

@media only screen and (min-width: 768px) {
    .imageLogo {
        width: 30%;
    }
    .recommendationSticker {
        float: left;
        max-width: 200px;
        position: absolute;
    }
}
.liPadding {
    padding: 20px;
}

@media only screen and (min-width: 992px) {
    .imageLogo {
        width: 40%;
    }
    .jumbotron {
        background: rgb(220, 218, 218);
        width: 100%;
        line-height: 5;
        margin-top: 10px;
        border-radius: 10px;
    }
    .modal-contents {
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 100%;
    }
}
@media only screen and (min-width: 300px) {
    .modal-contents {
        background-color: #fefefe;
        margin: auto;
        padding: 20px;
        border: 1px solid #888;
        width: 100%;
        position: absolute;
        bottom: 0;
    }
}