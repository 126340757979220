.detail-body-container {
	margin: 0 auto;
	font-size: 14px;
	background-color: #ffffff;
}
.detail-element-body {
	z-index: 1;
}
.detail-body {
	width: 100%;
}
.detail-header {
	text-align: left;
}
.name {
    font-size: 17px;
    font-family: 'Nunito', sans-serif;
    color: rgba(2, 2, 2, 0.87);
    margin-right: 5px;
}
.Dropdown-control {
    background: #FFFFFF;
    border: 1px solid #D9D9D9;
    box-sizing: border-box;
    border-radius: 4px;
    display: flex;
    flex-direction: row;
    height: 50px;
    padding: 10px;
    margin-left: 1%;
    font-family: 'Nunito', sans-serif;
}
.Dropdown-option {
	text-transform: capitalize;
	border-bottom: 1px solid #b4b4b4;
	width: 150px !important;
	background-color: #ffffff;
}
.more-filters {
    background-color: #F5F5F5;
    display: flex;
    flex-direction: column;
    padding: 1.25px 5%;
    text-align: left;
}

.filter-set-container{
    display: flex;
    flex-direction: row;
    align-items: baseline;
    padding-top: 0.75%;
}

.button {
    background-color: white;
    border-color: #f4f5f6;
    padding-left: 1rem;
    display: inline-block;
    margin: 0.5rem;
    width: 150px;
    height: 47px;
    color: rgba(0, 0, 0, 0.87);
    font-family: 'Nunito', sans-serif;
    font-size: 13.5px;
    font-weight: 500;
    text-align: left;
    text-transform: uppercase;
    border: 1px solid #dbdbdb;
}
.month-filter {
	background: #ffffff;
}
.searchBar input {
    width: 80%;
    border-radius: 8px;
    padding: 1rem 2rem;
    background-color: #eee !important;
}
.searchBar .clear-search {
	position: fixed;
	left: 85%;
	top: 1.1rem !important;
}

.searchBar .mdc-text-field {
	height: 40px !important;
}
.init-message,
.no-leads {
    padding: 2rem;
    font-size: 0.9rem;
    font-family: 'Nunito', sans-serif;
}
.no-leads {
	color: #b00020;
}
.lead-added {
	text-align: right;
	background-color: #e7e7e7;
	padding: 0.5rem 1rem;
}
.grouping-date {
	text-align: left;
	background-color: #f6f7f8;
	padding: 0.5rem 1rem;
}
.mobile-number {
	font-weight: bold;
	color: rgba(2, 2, 2, 0.87);
	margin-left: 5px;
}
.leads-detail {
	display: flex;
	margin-top: 10px;
}
.category-body {
	flex: 1;
	text-align: left;
}
.category {
	background-color: #ebebeb !important;
}
.category, .client, .role {
	font-weight: bold;
	color: #555555;
	font-size: 12px;
	letter-spacing: 0;
	line-height: 17px;
	margin: 5px;
	padding: 2px 8px;
	border-radius: 4px;
	background-color: #B9CBF2;
}
.client-status {
	float: right;
	text-align: center;
}
.icon-body {
	flex: 1;
	text-align: right;
}
.icon {
	margin-left: 20px;
}
.lead-status {
	text-align: left;
	margin-left: 35px;
}
.detail-panel {
	width: 100%;
}
.doc-uploaded {
	display: flex;
}
.doc-pending {
	display: flex;
}
.doc-text {
	height: 24px;
	width: 172px;
	text-align: left;
	margin-left: 20px;
}
.pending-right {
	flex: 1;
	text-align: right;
}
.upload-button {
	height: 32px;
	width: 84px;
	border-radius: 4px;
	background-color: #eebb25;
	border: none;
}
hr {
	border: 0;
	border-top: 1px solid #eee;
	margin-left: 43px;
}
.bottom-detail-wrapper {
	position: relative;
}
.navigation-container {
	position: fixed;
	bottom: 0;
	width: 100%;
}
.leads-wrapper {
    overflow-y: scroll;
    height: 70vh;
    background-color: #FFFFFF;
    padding: 0px 5%;
    padding-bottom: 16%;
}
.leads-error {
	margin-top: 2rem;
	font-weight: 500;
	font-size: 15px;
}
.loader_clip {
    margin-top: 10rem;
}
button.css-1r4vtzz,
button.css-48ayfv {
    background-color: #f4f5f6 !important;
    border-color: #f4f5f6 !important;
    border-radius: 5px !important;
    padding-left: 1rem;
    margin: 0.5rem;
    width: 150px;
    color: rgba(0, 0, 0, 0.87);
    font-family: 'Nunito', sans-serif;
    font-size: 13.5px;
    font-weight: 500;
    text-align: left;
    text-transform: uppercase;
}
.more-filters div .css-11unzgr {
	text-align: left;
}
.multiCatSelectCls, .multiSelectCls, .multiSelectCls div:first-of-type {
	display: inline;
}
.multiSelectCls div:first-of-type div.css-1jllj6i-control,
.css-6y1x9t-control,
.css-1wy0on6 {
    display: flex !important;
}
.btnWidth {
    margin-top: 8px;
}
.dot {
    height: 60%;
    width: 30%;
    background-color: #bbb;
    justify-content: center;
    align-items: center;
    border-radius: 100%;
    text-align: center;
    display: flex;
    font-size: 25px;
  }

  .imgResponse {
    max-width: 8vw;
}


@media screen and (max-width: 578px) {
    .button {
        width: 128px;
    }
    .imgResponse {
        max-width: 15vw;
    }
    .btnWidth {
        margin-top: 8px;
    }
    .dot {
        height: 65%;
        width: 80%;
        background-color: #bbb;
        border-radius: 50%;
        display: inline-block;
        font-size: 18px;
      }
}
@media screen and (min-height: 500px) and (max-height: 700px) {
	.leads-wrapper {
        height: 63vh;
	}
    /* .imgResponse {
        max-width: 10vw;
    } */
    .btnWidth {
        width: 30%;
        margin-top: 8px;
    }
    .dot {
        height: 8%;
        width: 8%;
        background-color: #bbb;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        text-align: center;
        display: flex;
        font-size: 18px;

      }
}

@media screen and (min-width: 700px) and (max-width: 1025px) {
	.leads-wrapper {
        overflow-y: scroll;
        height: 83vh;
        background-color: #FFFFFF;
        padding: 0px 5%;
        padding-bottom: 16%;
	}
}




@media only screen and (min-width: 992px) {
    /* .imgResponse {
        max-width: 10vw;
    } */
    .btnWidth {
        width: 30%;
        margin-top: 8px;
    }
    .dot {
        height: 60%;
        width: 30%;
        background-color: #bbb;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        text-align: center;
        display: flex;
        font-size: 25px;

      }
}
@media only screen and (min-width: 700px) {
    /* .imgResponse {
        max-width: 10vw; */
    /* } */
    .btnWidth {
        width: 30%;
        margin-top: 8px;
    }
    .dot {
        height: 60%;
        width: 30%;
        background-color: #bbb;
        justify-content: center;
        align-items: center;
        border-radius: 100%;
        text-align: center;
        display: flex;
      }
}
.contact-lead {
    width: 100%;
}

.contact-icon-container {
    border: 1px solid #D9D9D9;
    border-radius: 1px;
    background: #F5F5F5;
    display: flex;
    flex-direction: row;
    height: 62px;
    padding: 20px 10px;
}

.contact-detail {
    margin: 0px 0px 0px 15px;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 22px;
    color: #D34612;
}


.MuiExpansionPanelSummary-content {
    margin: 12px 0;
    display: flex;
    flex-grow: 1;
    transition: margin 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

.tabs-container{
    height: 50px;
    width: 100%;
    background-color: white;
    text-align: left;
    padding: 0px 5%;
}

.tab {
    width: 50%;
    display: block;
    text-align: center;
    float: left;
    padding: 10px;
    border-bottom: 2px solid #F0F0F0;
    height: 40px;
    cursor: pointer;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85)
}

.active-tab{
    border-bottom: 2px solid #D34612;
    color: #D34612;
    font-weight: bold;
}

.export-file-container{
    display: flex;
    padding-top: 1.5%;
    background: white;
}

.export-list{
    width: 50%;
    text-align: left;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: normal;
    line-height: 22px;
    color: #000000;
    padding: 5px 0px;
}

.download-file-container{
    width: 50%;
    text-align: right;
    padding-right: 10px;
}

.download-file-button{
    padding: 1px 8px;
    justify-content: center;
    align-items: center;
    width: 160px;
    background: #FFF7F4;
    border: 1px solid #D34612;
    box-sizing: border-box;
    box-shadow: 0px 2px 0px rgb(0 0 0 / 4%);
    border-radius: 4px;
    color: #D34612
}


/* candidate-details-popup */

.details-component-container{
    background: #FFFFFF;
    height: 100%;
    width: 100%;
}

.Contact-details-header{
    height: 11%;
    width: 100%;
    display: flex;
    flex-direction: row;
}

.Candidate-details{
    width: 90%;
    display: flex;
    flex-direction: column;

}

.head-details{
    width: 100%;
    display: flex;
    flex-direction: row;

}

.lead-image{
    height: 35px;
    width: 35px; 
    border-radius: 100px; 
    margin-right: 10px;
}

.name-number-container{
    width: 85%;
    height: 100%;
    display: flex;
    flex-direction: column;
    text-align: left;
    padding: 0px 13px;

}

.lead-name{
    height: 50%;
    width: 100%;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
    text-transform: capitalize;
}

.lead-number{
    height: 50%;
    width: 100%;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
}

.contact-buttons{
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 5px;
}

.call-now{
    width: 100%;
    justify-content: center;
    line-height: inherit;
    align-items: center;
    background: #FFF7F4;
    border: 1px solid #D34612;
    box-sizing: border-box;
    box-shadow: 0px 2px 0px rgb(0 0 0 / 4%);
    border-radius: 4px;
    color: #D34612;
    /* padding: 0px 12px; */
}

.message-button{
    width: 100%;
    line-height: inherit;
    background: #FFFFFF;
    justify-content: center;
    border: 1px dashed #D9D9D9;
    box-sizing: border-box;
    box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.016);
    border-radius: 4px;
    color: #D34612;
    padding: 0px 12px;
}

.phone-icon{
    width: 20px;
    height: 20px;
    padding: 2px 0px;
    border: 2px solid #f5703e;
    color: #f5703e;
    text-align: center;
    border-radius: 100px;
    background: #FFF7F4;
    font-size: 11px;
}

.whatsapp-icon{
    width: 20px;
    height: 20px;
    margin-top: -3px;
}



.close-popup{
    width: 10%;
    text-align: right;
    margin-top: -7px;
    font-size: 20px;
    color: #D34612;
}

.flow-diagram{
    padding: 10px 0px;
    height: 84%;
    width: 100%;
}

.single-path{
    display: flex;
    flex-direction: row;
    height: 11.11%;
    width: 100%;
    text-align: left;
}

.symbol{
    width: 10%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.circle-symbol{
    height: 50%;
    width: 100%;
    font-size: 20px;
    text-align: center;
    color: rgba(0, 0, 0, 0.45);
}

.pipe-symbol{
    height: 50%;
    width: 100%;
    text-align: center;
    color: rgba(0, 0, 0, 0.45);
}

.single-path-data{
    width: 45%;
    height: 100%;
    padding-left: 10px;
    padding-top: 3px;
}

.path-title{
    width: 100%;
    height: 50%;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
}

.path-text{
    width: 100%;
    height: 50%;
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.45);
}

#ApplicationDate1, #ClientName1{
    color: #D34612;
}

#ApplicationDate2, #ClientName2{
    color: #000000;
}

.Chips-container{
    display: flex;
    flex-direction: row;
    height: 50%;
    align-items: center;
    margin-bottom: 5px;
    flex-wrap: wrap;
}

.single-chip{
    background: #FFFFFF;
    border: 1px solid #F0F0F0;
    box-sizing: border-box;
    border-radius: 2px;
    display: flex;
    flex-direction: row;
    width: -moz-fit-content;
    width: fit-content;
    padding: 2px 4px 2px 8px;
    margin: 4px;
}

.chip-text{
    width: 80%;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 17px;
    line-height: 20px;
    color: rgba(0, 0, 0, 0.85);
    width: fit-content;
}

.chip-cross{
    color: #FF4D4F;
    padding: 0px 8px;
    margin-top: -2px;
}

.tool-tip {
    position: relative;
    display: inline-block;
  }
  
  .tool-tip .tooltiptext {
    visibility: hidden;
    width: 135px;
    background-color: #555;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px;
    position: absolute;
    z-index: 1;
    bottom: 103%;
    left: 50%;
    margin-left: -60px;
    opacity: 0;
    transition: opacity 0.3s;
  }
  
  .tool-tip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 100%;
    left: 50%;
    margin-left: -5px;
    border-width: 5px;
    border-style: solid;
    border-color: #555 transparent transparent transparent;
  }
  
  .tool-tip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
  }

  .header{
    background: rgb(255, 255, 255);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1% 5%;
  }

  .header-text{
      width: 50%;
      display: flex;
      flex-direction: row;
  }

  .breadcrumb-new{
    text-align: left;
    padding: 0 10px;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
  }

  .icon-right{
      line-height: inherit;
  }

  .header-search{
    /* width: 50%; */
    background: #FFFFFF;
    box-sizing: border-box;
    border-radius: 2px;
    align-items: center;
    margin-left: auto;
    height: 32px;
    display: flex;
    flex-direction: row;
  } ::placeholder{
    font-family: 'Nunito', sans-serif;
  }

  .search-icon{
    padding: 0px 5px;
    margin-left: -28px;
    text-align: center;
    align-items: center;
    border-left: 1px solid rgb(133, 133, 133);
    color: rgb(133, 133, 133);
    background: white;
    height: 23px;
  }

  .search-input{
    width: 100%;
    height: 28px;
  }

  .search-input::placeholder{
    color: rgb(133, 133, 133)
  }

  .phoneNumber{
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 13px;
    padding: 0px 5px;
    line-height: 22px;
    color: rgba(0, 0, 0, 0.85);;
  }

  .phone-container{
      display: flex;
      flex-direction: row;
    cursor: pointer;
      
  }

  .card-container{
      display: flex;
      flex-direction: row;
      padding: 16px;
      margin-top: 10px;
      width: 100%;
      background: #FFFFFF;
      border: 1px solid #F0F0F0;
      box-sizing: border-box;
      border-radius: 4px;
  } 
  .card-container:hover{
      box-shadow: 0 2px 8px 0 rgba(0,0,0,0.15);
  }
  .contact-details-container{
    display: flex;
    flex-direction: row;
  }
  .contact-details{
      display: flex;
      flex-direction: column;
      display: flex;
      flex-direction: column;
      width: 50%;
      text-align: left;
  }
  .lead-name{
    font-family: 'Nunito', sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 16px;
    line-height: 24px;
    color: rgba(0, 0, 0, 0.85);
  }
  .phone-container{
      display: flex;
      flex-direction: row;
  }

  .user-image-view-detail{
      display: flex;
      flex-direction: column;
      width: 15%;
      text-align: right;
    

  }

  .user-image{
    width: 56px;
    height: 56px;
    background: #D9D9D9;
    margin-left: auto;
    border-radius: 100px;
    text-align: center;
    align-items: center;
    padding: 7px 10px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 40px;
    text-align: center;
    color: #FFFFFF;
  }
  .view-details-container{
    background: #FFF7F4;
    border: 1px solid #D34612;
    margin-left: auto;
    box-sizing: border-box;
    box-shadow: 0px 2px 0px rgb(0 0 0 / 2%);
    border-radius: 4px;
    width: -moz-fit-content;
    width: fit-content;
    padding: 5px 16px;
    margin-top: 20px;
    cursor: pointer;
  }
  .job-container{
    display: flex;
    padding: 10px 0px;
    flex-direction: column;
  }

  .single-job-container{
      display: flex;
      flex-direction: row;
  }

  .client-logo{
      /* width: 16%; */
  }

  .onboarding-schedule{
    width: 63%;
    padding-left: 2px;
    text-align: left;
  }

  .counter.counter-lg {
        margin-left: 26px;
        margin-top: -51px;
        background: #D34612;
        border: 1px solid #FFFFFF;
        width: 20px;
        border-radius: 100px;
        height: 20px;
        font-size: 13px;
        text-align: center;
        line-height: 20px;
        vertical-align: middle;
}

    @media screen and (max-width: 947px) {
    .view-details-container{
        border-radius: 100px;
        padding: 4px 8px;
        height: 30px;
        width: 30px;
    }
    .view-details-button{
        display: none;
    }
}

.filter-label{
    padding: 0px 10px;
    line-height: inherit;
}

@media (max-width: 845px){
    .Show-breadcrumb{
        display: none;
    }
}

.warning-message-atrisk{
    background: #FFF7F4;
    border: 1px solid #D34612;
    box-sizing: border-box;
    border-radius: 2px;
    padding: 1%;
	display: flex;
	flex-direction: row;
    }

    .bottom-sheet-container{
        display: flex;
        flex-direction: column;
        padding: 10px;
    }

    .bottom-sheet-heading{
        padding: 10px;
        display: flex;
        flex-direction: row;
        border-bottom:  1px solid #F0F0F0;
    }

    .bottom-sheet-heading-text{
        font-family: 'Nunito', sans-serif;
        width: 90%;
        color: black;
        font-weight: bold;
        text-align: left;
    }

    .bottom-sheet-close{
        color: rgba(0, 0, 0, 0.55);
        text-align: right;
        margin: auto;
        line-height: inherit;
    }

    .bottom-sheet-contact-details-container{
        display: flex;
        flex-direction: column;
        padding: 1% 0%;
    }

    .bottom-sheet-contact-options{
        display: flex;
        flex-direction: row;
    }

    .bottom-sheet-contact-button{
        margin-left: auto;
        /* padding: 0px 50px; */
    }

    .bottom-sheet-index{
        padding: 0.9% 2%;
        width: 10%;
        line-height: inherit;
        font-family: 'Nunito', sans-serif;
        font-size: 14px;
        line-height: 20px;
        display: flex;
        text-align: center;
        color: #FFFFFF;
        box-sizing: border-box;
    }

    .index-number{
        border-radius: 32px;
        width: 20px;
        height: 20px;
        background: #D34612;
        border: 1px solid #D34612;
        font-family: 'Nunito', sans-serif;
    }

    .bottom-sheet-contact-text{
        display: flex;
        flex-direction: column;
        font-family: 'Nunito', sans-serif;
        width: 60%;
        text-align: left;
        font-weight: bold;
    }
    .lead-number{
        font-family: 'Nunito', sans-serif;
    }


.warning-message-at-risk-container{
    background-color: white;
    width: 100%;
    text-align: left;
}

.icon-cross{
    float: right;
    line-height: inherit;
    color: rgba(0, 0, 0, 0.5);
    margin: auto 10px;
}

.bottom-sheet-buttons-layout{
    text-decoration:none;
    color: rgba(0, 0, 0, 0.85);
    width: 100%;
    height: 100%;
}
.filter-pop-up-container{
    display: flex;
    flex-direction: column;
}

.filter-popup-heading-container{
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #F0F0F0;
    height: 15%;
    padding: 10px;
}

.select-client{
    width: 80%;
    text-align: left;
    font-weight: bold;
}

.filter-popup-cross-icon{
    width: 20%;
    text-align: right;
    margin-left: auto
}

.popup-list-container{
    display: flex;
    flex-direction: column;
    margin-top: 10px;
}

.single-filter-item{
    margin: 10px;
    text-align: justify;
}

@media (max-width: 360px){
    .more-filters{
        background-color: #F5F5F5;
        display: flex;
        flex-direction: column;
        text-align: left;
        padding: 0
    }
    .Dropdown-control {
        background: #FFFFFF;
        border: 1px solid #D9D9D9;
        box-sizing: border-box;
        border-radius: 4px;
        display: flex;
        flex-direction: row;
        height: 50px;
        padding: 10px 2px;
        margin-left: 1%;
        font-family: 'Nunito', sans-serif;
    }    
    .tab {
        width: 50%;
        display: block;
        text-align: center;
        float: left;
        border-bottom: 2px solid #F0F0F0;
        height: 40px;
        cursor: pointer;
        font-family: 'Nunito', sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 12px;
        line-height: 22px;
        color: rgba(0, 0, 0, 0.85);
    }
}
@media (min-height: 768px){

    .header {
        background: rgb(255, 255, 255);
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 1.7% 5%;
        height: 43px;    
    }
}